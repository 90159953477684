import React from 'react';

class OurValue extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    };

    render() {
        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b80 bg-white">
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="mt-separator-outer separator-left">
                                            <div className="mt-separator">
                                                <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Nos</span> Valeurs</h2>
                                            </div>
                                            <p>NEW COMBUST s’engage au respect de la principale
                                                norme européenne encadrant la production de granulés
                                                de bois de catégorie A1 (dédiés au chauffage domestique)
                                                ainsi que celle <b>EN 14-961-2</b> qui correspond à la norme
                                                internationale <b>EN ISO 17-225-2.</b>
                                            </p>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="author-info p-t20">
                                        <div className="author-name">
                                            <h4 className="m-t0">M.DUVAL Bertrand.</h4>
                                            <p>Distributeur officiel et exclusif / France.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-6">
                                    <div className="p-b0">
                                        <div className="mt-box">
                                            <h3 className="m-t0">
                                                <span className="font-weight-100">Chez NEW COMBUST,</span>
                                                <span className="text-primary"> le service clients n'est pas seulement un département;  
                                                C'est l'affaire de toute l'entreprise.</span></h3>
                                        </div>
                                        <div className="author-signature">
                                            <img src={require('./../../@core/images/pages/en-iso.png')} alt="Signature" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <div className="section-head">
                                        <div className="mt-separator-outer separator-left">
                                            <div className="mt-separator">
                                                <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Nos </span>objectifs</h2>
                                            </div>
                                            <p>Aussi <b>NEW COMBUST</b> ouvre pour l’obtention des trois
                                                certifications a savoir
                                            </p>
                                        </div>
                                    </div>
                                    <div className="author-signature">
                                        <img src={require('./../../@core/images/pages/din-plus.png')} alt="Signature" width={150} />
                                    </div>
                                    <div className="author-signature">
                                        <img src={require('./../../@core/images/pages/nf-plus.png')} alt="Signature" width={150} />
                                    </div>
                                    <div className="author-signature">
                                        <img src={require('./../../@core/images/pages/en-plus.png')} alt="Signature" width={150} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
};

export default OurValue;