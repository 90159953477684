import React, { useEffect } from 'react';
import Header from '../../@core/components/Common/Header';
import Banner from '../../@core/components/Common/Banner';
import AboutSummary from '../../@core/components/AboutSummary';
import Footer from '../../@core/components/Common/Footer2';

var bnrimg = require('../../@core/images/pages/img-5.webp');

const About = () => {
    useEffect(() => {
        document.title = 'À propos - New Combust';
    }, []);

    return (
        <>
            <Header />
            <div className="page-content">
                <Banner title="PRODUCTEURS DE BOIS ÉNERGIE SOUS FORME DE GRANULÉS." pagename="À propos" bgimage={bnrimg} />
                <AboutSummary />
            </div>
            <Footer />
        </>
    );
};

export default About;