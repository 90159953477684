import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const images = [
    require('./../images/products/sac-15.png'),
]

var bnr1 = require('./../images/background/line.png');

class About3 extends React.Component {
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('./../../assets/js/masonary.js');
      
    };
    render() {
        const options = {
            loop:false,
            autoplay:true,
            autoplayTimeout:3000,
            margin:30,
            nav:false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items:1,
            dots: true
        };
        return (
            <>
                <div className="section-full p-t30 p-b80 bg-dark text-white inner-page-padding">
                    <div className="container">
                        <div className="section-content ">
                            <div className="row">
                                <div className="col-lg-5 col-md-5">
                                    <div className="m-about ">
                                        <OwlCarousel className="owl-carousel about-us-carousel" {...options}>
                                            {images.map((item, index) => (
                                                <div className="" key={index}>
                                                    <div className="ow-img">
                                                    <NavLink to="#"><img src={item} alt=""/></NavLink>
                                                    </div>
                                                </div>
                                            ))}
                                        
                                        </OwlCarousel>
                                        
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7">
                                    <div className="m-about-containt text-black p-t80">
                                        <div className="m-about-years bg-white bg-moving" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                                            <span className="text-primary large-title">Sac de 15 <small>kg</small></span>
                                            <span className="large-title-info">Respectant l'environnement</span>
                                        </div>
                                        <h3 className="font-weight-600">Découvrir nos granulés de bois à l’unité ou en palette.</h3>
                                        <p>Découvrez nos granulés de bois vendus en sacs individuels de
                                        15kg , en palette de 66 sacs ou en vrac sous forme de Big Bag.</p>
                                        <div className="author-info p-t20">
                                            <div className="author-name">
                                                <h4 className="m-t0">M.DUVAL Bertrand.</h4>
                                                <p>Distributeur officiel et exclusif / France.</p>
                                            </div>
                                            <NavLink to="#" className="site-button btn-effect m-b15">Découvrir<span> plus</span></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default About3;