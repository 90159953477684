// eslint-disable-next-line
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './views/pages/Home';
import About from './views/pages/About';
import ContactUs from './views/pages/ContactUs';

import ScrollToTop from './@core/components/Common/ScrollToTop';
import Error from './@core/components/Common/Error';

class AppRoutes extends React.Component {
    render() {
        return (
            <BrowserRouter basename="/">
                <ScrollToTop />
                <div className="page-wraper">
                        <Routes>
                            <Route path="/"  element={<Home/>} />
                            <Route path="/aboutus"  element={<About/>} />
                            <Route path="/contactus"  element={<ContactUs/>} />
                            
                            
                            
                            <Route path='*' element={<Error/>} />
                        </Routes>
                </div>
            </BrowserRouter>
        );
    };
};

export default AppRoutes;