import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer2';
import Banner from './Banner';

var bnrimg = require('../../images/pages/img-5.webp');

class Error extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                <Banner title="Page non trouvée" pagename="Page d'erreur" bgimage={bnrimg}/>
                   
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-tb150">
                        <div className="container">
                            <div className="section-content">
                                <div className="page-notfound text-center">
                                    <strong>Page non trouvée</strong>
                                    <span className="title">404</span>
                                    <p>La page que vous cherchez n'est pas ici !</p>
                                    <NavLink to="/" title="Retour à l'accueil" className="site-button btn-effect">Accueil</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>


                <Footer />

            </>
        );
    };
};

export default Error;