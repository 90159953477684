import React, { useEffect } from 'react';
import Header from '../../@core/components/Common/Header';
import Banner from '../../@core/components/Common/Banner';
import Footer from '../../@core/components/Common/Footer2';

var bnrimg = require('../../@core/images/pages/img-5.webp');

const ContactUs = () => {
    useEffect(() => {
        document.title = 'Contactez-Nous - New Combust';
    }, []);

    return (
        <>
            <Header />
            <div className="page-content">
                <Banner title="PRODUCTEURS DE BOIS ÉNERGIE SOUS FORME DE GRANULÉS." pagename="Contactez-Nous" bgimage={bnrimg} />

                {/* SECTION CONTENT START */}
                <div className="section-full p-tb80 inner-page-padding">
                    {/* LOCATION BLOCK */}
                    <div className="container">
                        {/* GOOGLE MAP & CONTACT FORM */}
                        <div className="section-content">
                            {/* CONTACT FORM */}
                            <div className="row">
                                <div className="col-md-8 col-sm-6">
                                    <form className="contact-form cons-contact-form" method="post" action="#">
                                        <div className="contact-one m-b30">
                                            {/* TITLE START */}
                                            <div className="section-head">
                                                <div className="mt-separator-outer separator-left">
                                                    <div className="mt-separator">
                                                        <h2 className="text-uppercase sep-line-one">
                                                            <span className="font-weight-300 text-primary">Get</span> In touch
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* TITLE END */}
                                            <div className="form-group">
                                                <input name="username" type="text" required className="form-control" placeholder="Name" />
                                            </div>
                                            <div className="form-group">
                                                <input name="email" type="text" className="form-control" required placeholder="Email" />
                                            </div>
                                            <div className="form-group">
                                                <textarea name="message" rows={4} className="form-control " required placeholder="Message" defaultValue={""} />
                                            </div>
                                            <div className="text-right">
                                                <button name="submit" type="submit" value="Submit" className="site-button btn-effect">submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-4 col-sm-6 ">
                                    <div className="contact-info m-b30 ">
                                        {/* TITLE START */}
                                        <div className="section-head">
                                            <div className="mt-separator-outer separator-left">
                                                <div className="mt-separator">
                                                    <h2 className="text-uppercase sep-line-one ">
                                                        <span className="font-weight-300 text-primary">Contact</span> Info
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                        {/* TITLE END */}
                                        <div className="bg-secondary p-a20 text-white">
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Phone number</h5>
                                                    <p>+213 553 17 77 39 / +213 555 55 61 91</p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Email address</h5>
                                                    <p>contact@newcombust.com</p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper left">
                                                <div className="icon-xs"><i className="fa fa-map-marker" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Address info</h5>
                                                    <p>Siege Social: Centre Commercial Al Kods
                                                        BU 08/18, Cheraga, Alger Algérie.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gmap-outline">
                            <div style={{ height: '400px', width: '100%' }}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12785.862403063385!2d2.9635665!3d36.7593964!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128fb18b66019939%3A0x5074fdfa121c3e95!2sNewCombust!5e0!3m2!1sen!2sdz!4v1721498908865!5m2!1sen!2sdz"
                                    width="100%"
                                    height="100%"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    title="Adresse Siege"
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                {/* SECTION CONTENT END */}
            </div>
            <Footer />
        </>
    );
};

export default ContactUs;
