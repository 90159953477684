import React from 'react';

var img1 = require('./../../images/background/bg-6.png');
var img2 = require('./../../images/background/line.png');

class AboutSummary extends React.Component {
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('./assets/js/masonary.js');
      
    };
    render() {
        return (
            <>
                <div id='aboutus' className="section-full p-t80 p-b80 bg-dark bg-repeat square_shape2 inner-page-padding" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-left">
                                <div className="mt-separator text-white">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">À Propos</span> Résumé</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content our-story">
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../images/pages/bg-intro.png')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover" style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        <h4 className="text-uppercase text-secondary m-b20">QUI SOMME NOUS?</h4>
                                        <p><b>NEW COMBUST</b> est une entreprise de premier plan dans le domaine de la production de granulés de bois (pellets) de haute qualité (bois de classe A). Grâce à la maîtrise des mélanges et à un processus de compactage du matériau lignocellulosique (petite granulométrie), nous obtenons :
                                        </p>    
                                        <ul style={{paddingLeft: '50px'}}>
                                            <li>Des cylindres de 6 à 10 mm de diamètre et de 20 à 40 mm de longueur.</li>
                                            <li>Un taux d’humidité inférieur à 10 % Hbh.</li>
                                            <li>Un pouvoir calorifique élevé.</li>
                                            <li>Un temps de combustion prolongé et multiple.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('../images/pages/img-1.jpg')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        <h4 className="text-uppercase text-secondary m-b20">Matière Première</h4>
                                        <p><b>NEW COMBUST</b>, accorde une importance haute a la sélection et au traitements des matières premières
                                            car le choix des matières premières utilisées et leurs traitements sont des facteurs déterminants pour la
                                            qualité de son pellet final.</p>
                                        <p><b>NEW COMBUST</b>, s’alimente en différentes essences de bois afin de répondre au mieux aux différentes
                                        exigences et demandes de ces clients et partenaires.</p>
                                        <p>Nous prévoyons à court et
                                            a moyen termes d’avoir
                                            nos propres plantations afin d’assurer la qualité ainsi
                                            que la disponibilité de notre matière première</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../images/products/sac-15.png')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        <h4 className="text-uppercase text-secondary m-b20">Emballage & Conditionnement</h4>
                                        <p className="m-b20"><b>NEW COMBUST</b>,
                                            est équipée d’une unité d'emballage automatique qui
                                            dispose d'une fonction de détection en ligne en temps réel ce qui convient
                                            aux machines d'alimentations de sacs.
                                            L'unité adopte un plateau pour l'approvisionnement en sacs, pouvant stocker
                                            plus de 200 sacs vides elle emballe en 15, 25 et 50Kg/sac, le poids de chaque
                                            sac peut être ajusté, le taux de précision du poids est de 0,2% et la capacité
                                            de production est de 500 sacs/h.</p>
                                        <p>Elle dispose aussi d’un système qui comprend une rembobineuse linéaire, un
                                            convoyeur grimpant, un convoyeur de mise en forme et de pressage par
                                            vibration, un convoyeur à préhension, un contrôle automatique de la liaison
                                            avec une machine d'emballage et un palettiseur.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../images/pages/bg-delivery.png')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        <h4 className="text-uppercase text-secondary m-b20">Distribution & Commercialisation</h4>
                                        <h4 className="m-b20">Bien choisir son canal de distribution est essentiel !!!</h4>
                                        <p><b>NEW COMBUST</b> considère que la distribution des granules de bois est une activité à part entière, nécessitant une connaissance approfondie de la stratégie des concurrents, y compris leur circuit de commercialisation, leur mode de livraison, leurs produits et services, leur stratégie de communication et leur politique de prix. Il est donc essentiel d'obtenir un maximum d'informations sur les habitudes d'achat des clients : les marques qu'ils consomment, le moment où ils consomment et la manière dont ils consomment. Ces données permettront d'adapter les canaux de distribution au comportement d'achat des clients, rapprochant ainsi les clients de la marque et renforçant leur confiance.</p>
                                        <p>
                                        Pour cela, <b>NEW COMBUST</b> a choisi d'être représentée par des professionnels du domaine et d'utiliser les canaux les plus courts en B2B, afin d'entretenir une relation étroite avec ses clients. Cette approche vise à fidéliser les contrats, en particulier lorsqu'ils représentent une part importante des résultats de l'entreprise.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default AboutSummary;