import React from 'react';
import { NavLink } from 'react-router-dom';

var bnr1 = require('./../../images/background/bg-5.png');

class Specialization extends React.Component {
    render() {
        return (
            <>
                <div id='our-products' className="section-full mobile-page-padding bg-white p-t80 p-b30 bg-repeat square_shape1" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-center">
                                <div className="mt-separator">
                                    <h2 className="text-dark text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Nos</span> Produits</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../images/products/sac-15.png')} alt=""/>
                                        <div className="figcaption">
                                            <h4>Sac 15 kg</h4>
                                            <p>En sacs individuels de 15kg</p>
                                            <NavLink to="#" ><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4  col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../images/products/pellet.png')} alt="" />
                                        <div className="figcaption">
                                            <h4>Pellet 66 pcs</h4>
                                            <p>En palette de 66 sacs</p>
                                            <NavLink to="#" ><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4  col-sm-6 m-b30">
                                    <div className="image-effect-one  hover-shadow">
                                        <img src={require('../images/products/sac-envrac.png')} alt="" />
                                        <div className="figcaption bg-dark">
                                            <h4>Big Bag</h4>
                                            <p>En vrac sous forme de Big Bag</p>
                                            <NavLink to="#" ><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-12 col-sm-6 m-b30">
                                    <div className="mt-box our-speciallization-content">
                                        <h3 className="m-t0"><span className="font-weight-100">Le bon</span> <br />choix.</h3>
                                        <p>Faire le choix de nos granulés de bois, c'est choisir la qualité,
                                        la performance et l'engagement environnemental.</p>
                                        <NavLink to="#"  className="site-button btn-effect">Voir tout</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-right p-r50 text-uppercase text-pop-up-top">
                        <strong>Nos Produits</strong>
                    </div>
                </div>
            </>
        );
    }
};

export default Specialization;