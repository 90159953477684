import React, { useEffect } from 'react';
import Header from '../../@core/components/Common/Header';
import Footer from '../../@core/components/Common/Footer2';
import Hero from '../../@core/components/Hero';
import OurValue from '../../@core/components/OurValue';
import OurProduct from '../../@core/components/Specialization';

const Home = () => {
    useEffect(() => {
        document.title = 'Accueil - New Combust';
    }, []);

    return (
        <>
            <Header />
            <div className="page-content">
                <Hero />
                <OurProduct />
                <OurValue />
            </div>
            <Footer />
        </>
    );
};

export default Home;
